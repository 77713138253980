/* Reset some default styles */
body,
h1,
h2,
h3,
p,
ol {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Arial", sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 95vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #2c3e50;
  color: #fff;
  padding: 0px 0;
  text-align: center;
}

nav {
  background-color: #34495e;
  color: #ecf0f1;
  padding: 15px 100px 15px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav div {
  justify-content: space-between;
}

nav a {
  color: #ecf0f1;
  text-decoration: none;
  margin: 0 10px;
  font-weight: bold;
}

main {
  padding: 20px;
}

section {
  margin-bottom: 20px;
}

h1,
h2,
h3 {
  color: #3498db;
}

.navButtonContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.videoplayercontainer{
  justify-content: center ;
  padding: 10px 10px 122px 10px;
}
button.navigation {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  max-width: 55px;
  max-height: 55px;
  border: none;
}
button.navigation:hover{
  background-color: #f5f5f5;
}

button.send {
  background-color: #3498db;
  color: #f5f5f5;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  max-width: 80px;
  max-height: 50px;
  border: none;
  font-size: 20px;
}

button:hover {
  opacity: 0.8;
}
footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
  margin-top: 20px;
}
.videocontainer {
  display: flex;
  justify-content: space-evenly;
  padding-top: 30px;
  padding-bottom: 0px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
}
li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}
li a.active {
  background-color: #3498db;
  color: white;
}
li a:hover:not(.active) {
  background-color: #555;
  color: white;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 98vh;
  padding: 20px;
  background: linear-gradient(20deg, #2c3e50, rgba(148, 187, 233, 0.7));
}
.login-form {
  display: grid;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 500px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: #f5f5f5;
}
.login-container p {
  color: #000000;
}
.login-container .login-form input {
  background-color: rgba(194, 187, 187, 0.05);
  color: #000000;
  outline: none;
  border: 2px solid white;
  border-radius: 4px;
}
.login-container .login-form input::placeholder {
  color: #f5f5f5;
  opacity: 1;
}
.login-container .login-form button {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  border: 2px solid white;
  color: #f5f5f5;
  max-width: 60px;
}
.login-container .login-form button:hover {
  opacity: 0.8;
}
.login-container .login-form input {
  padding: 5px;
  max-width: 300px;
}
.nav-link {
  cursor: pointer;
}
.nav-link:hover {
  color: white;
}
/* Assuming you have a class or ID for your table */
.medical-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}
/* Style for the table header */
.medical-table th {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  text-align: left;
}
/* Style for alternate rows */
.medical-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
/* Style for table cells */
.medical-table td,
.medical-table th {
  border: 1px solid #ddd;
  padding: 8px;
}
/* Hover effect on table rows */
.medical-table tbody tr:hover {
  background-color: #d3e0ea;
  transition: background-color 0.3s;
}
.dialog-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.dialog-content {
  text-align: center;
}
.dialog-title {
  color: #3498db; /* Green color */
}
.dialog-message {
  margin-top: 10px;
  color: #333;
}
.dialog-buttons {
  margin-top: 20px;
}
.confirm-button,
.cancel-button {
  padding: 8px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.confirm-button {
  background-color: #3498db; /* Green color */
  color: #fff;
}
.cancel-button {
  background-color: #d9534f; /* Red color */
  color: #fff;
}
.logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #3498db; /* Green color */
  color: #fff;
  cursor: pointer;
}
.logout-button:hover {
  background-color: #3498db; /* Darker green color on hover */
}
.vidlist{
  text-align: right;
  font-size: 18px;
}
.vidlist li a {
  display: block;
  padding: 0px 16px;
  text-decoration: none;
}
.vidlist li a.selected {
  background-color: #dbd534;
  color: white;
}
.vidlist li a:hover:not(.labelled) {
  background-color: #555;
  color: white;
}
.page-container {
  position: relative;
  min-height: 100vh;
}
.content-wrap {
  padding-bottom: 5rem;    /* Footer height */
}